import React from 'react'
import './Services.css'
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container service__container">
         <article className='service'>
          <div className="service__head">
            <h3>UI/UX</h3>
          </div>
            <ul className='service__list'>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Information architecture.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Design technology consulting.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Brand and design system development.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Brand strengthening digital interfaces.</p>
              </li>
            </ul>
        </article>
        {/* END OF UI/UX} */}
        <article className='service'>
          <div className="service__head">
            <h3>WEB DEVELOPMENT</h3>
          </div>
            <ul className='service__list'>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Graphic Design.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Logo Design.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Hosting.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>WordPress Support.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Ongoing Website Maintenance.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Social Media Management.</p>
              </li>
            </ul>
        </article>
        {/* END OF WEB DEVELOPMENT */}

        <article className='service'>
          <div className="service__head">
            <h3>CONTENT CREATION</h3>
          </div>
            <ul className='service__list'>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Blog posts.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Newsletters writing.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Podcasts.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Video production and editing.</p>
              </li>
              <li>
                <BiCheck className='service__list-icon' />
                <p>Posters creation.</p>
              </li>
            </ul>
        </article>
        {/* END OF CONTENT CREATION */}
      </div>
    </section>
  )
}

export default Services