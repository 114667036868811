import React from 'react'
import './Header.css'
import CTA from './CTA'
import ME from '../../assets/me1.jpg'
import HeaderSocials from './HeaderSocials'

const Header = () => {
  return (
    <section id='home'>
    <header>
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>Kinoti Dennis</h1>
        <h5 className="text-light">Fullstack Developer</h5>
        <CTA />
        < HeaderSocials />

        <div className="me">
          <img src={ME} alt='me' />
        </div>

        <a href='#footer' className='scroll__down'>Scroll Down</a>
      </div>
    </header>
    </section>
  )
}

export default Header