import React from 'react'
import './Testimonials.css'
import Avtr1 from '../../assets/avatar1.jpg'
import Avtr2 from '../../assets/avatar2.jpg'
import Avtr3 from '../../assets/avatar3.jpg'
import Avtr4 from '../../assets/avatar4.jpg'

// import Swiper core and required modules
import {Pagination} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


const data = [
  {
    avatar: Avtr1,
    name: 'Tina Snow',
    review: 'I have worked with Kinoti and I can attest that he does some pretty amazing work. You should consider hiring him.'
  },
  {
    avatar: Avtr2,
    name: 'Shatta Wale',
    review: 'The skills, hardwork and determination portrayed by this young lad is really commendable. Give him a chance to do some work for you and I assure you you will not be dissapointed.'
  },
  {
    avatar: Avtr3,
    name: 'Kwame Despite',
    review: 'It was a really great experience working with Kinoti. I was able to learn a lot about web development from him and I really thank him a lot for sharing his skills and knowledge with me.'
  },
  {
    avatar: Avtr4,
    name: 'Nana Ama McBrown',
    review: 'He is a very hadrwoking and ambitious young man. He has saved me a lot over the many times we have worked together. I would highly recommend him to anyone who needs a website or any other skill concerning web development and design.'
  }
]

const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Review from Clients</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container"
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {
          data.map(({avatar, name, review}, index) =>{
            return (
          <SwiperSlide key={index} className='testimonial'>
            <div className="client__avatar">
              <img src={avatar} alt=''/>
            </div>
              <h5 className='client__name'>{name}</h5>
              <small className='client__review'>{review}</small>
          </SwiperSlide>
            )
          })
        }
      </Swiper>
    </section>
  )
}

export default Testimonials